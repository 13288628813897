import * as React from 'react';
import footerContent from './Footer/data';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            footer: ''
        }
        this.__ = this.props.global.__;

    }

    componentDidMount() {
        this.props.global.scripts('#fLoader', [
            //  {src:'assets/js/plugins.js', attr: { } },
            //  {src:'assets/js/scripts.js', attr: { } }
        ])
    }


    render() {
        return (<>
            <div id='fLoader'></div>
            <div id="footer">
                <footer>
                    <div className="footer-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    {footerContent.section1.content()}
                                </div>
                                <div className="col-md-4">
                                    {footerContent.section2.content()}
                                </div>
                                <div className="col-md-4">
                                    {footerContent.section3.content()}
                                </div>
                            </div>
                        </div>
                        <div className="container p-5">
                            <div className="text-center">
                            <img src="/assets/img/sathya-sai-signature.png" className="col-md-8" alt="signature" width="100%" />
                            </div>
                            <div className="row justify-content-center pt-3 pb-5 pb-md-0 text-sm">
                            <div className="col col-md-auto text-center">
                                <img src="/assets/img/footer-logo.svg" style={{maxWidth:'72px'}} />
                                <br />
                                <small className="text-muted">
                                &copy; Sri Sathya Sai Central Trust, Prasanthi Nilayam. All rights
                                reserved.
                                </small>
                                <p className="mt-2">
                                
                                <a className="text-muted" rel="noopener noreferrer" target="_blank" href="https://www.srisathyasai.org/pages/privacypolicy.html">
                                    Privacy Policy and Copyright Information
                                </a>
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>)
    }
}



export default Footer;