import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export default class DsDatePicker extends React.Component{
  constructor (props) {
    super(props);
    let selected = window.localStorage.getItem('selectedate');
    selected = selected === undefined || selected === null ? new Date() : new Date(selected);
    
    this.state = {
      dates: [],
      startDate: new Date(),
      endDate: new Date(),
      selected: selected,
      allowedDates: (dt)=>{
        return;
      },
      slots: []
    }
  }
  componentDidMount(){
    let selected = window.localStorage.getItem('selectedate');
    selected = selected === undefined || selected === null ? new Date() : new Date(selected);
    
    this.handleChange(selected);
    this.getDates();
  }
  
  getDates = async () => {
    const ndates = await this.props.global.getAvailableDates();
    this.adates = ndates;
      if(Array.isArray(ndates)){
        let xdt = ndates.map((x)=>{
          var ndt = x[0].split('-');
          ndt[1] = parseInt(ndt[1]) - 1;
          ndt[1] = (ndt[1] < 10)  ? '0' + (ndt[1]) : ndt[1];
          ndt = ndt.join('-');
          new Date(ndt + ' 00:00:00');
          return ndt
      });
      
      
      this.setState({
        startDate: new Date(this.props.global.firstDate(ndates)),
        endDate: new Date(this.props.global.lastDate(ndates)),
        dates: ndates,
        allowedDates: (date) => {
        
            let m = (date.getMonth() < 10)  ? '0' + (date.getMonth()) : date.getMonth();
            let d = (date.getDate() < 10)  ? '0' + (date.getDate()) : date.getDate();
            let dt =  date.getFullYear() + '-' + m + '-' + d;
          /// 
            if(xdt.includes(dt)){
            // 
              return true;
            }else{
              return false;
            }
            
        }
      });
    }
  
 // return Array.isArray(dates) && dates.length > 0 ? dates : [];
}

  handleMonthChange(date) {  
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    let dt = {
      'd':date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
      'm':month[date.getMonth()],
      'y':date.getFullYear()
    };
    let slot = window.localStorage.getItem('formdata');
    try {
        slot = JSON.parse(slot);
        slot = JSON.parse(slot.slot);
        this.props.state.selectedSlot = slot;
    } catch (error) {
        
    }
    document.querySelector('.react-datepicker__current-month').innerHTML = '<span>' + dt.d + '</span> ' + dt.m.toString() + ' ' + dt.y.toString();
  }

  handleChange = async(date) => {  
    window.localStorage.setItem('selectedate',date);
    this.setState({
      selected: date
    });
    window.scroll({
        top: window.scrollY > 0 ? window.scrollY + 1 : 1,
        left: 0,
        behavior: 'smooth'
    });
    date = this.props.global.datePicker(date,0,'YM1D');
    this.props.global.slots = await this.props.global.getSlots(date);
    if(Array.isArray(this.props.global.slots) && this.props.global.slots.length > 0){
      if(document.getElementById('slots') !== undefined && document.getElementById('slots') !== null){
        document.getElementById('slots').focus();
        window.location.href = '/#rb_slot';
        
      }
      
      this.props.selectedSlot();
      
    }
  }
  render(){
    
    return (<>
    {
      this.state !== null ? 
      <DatePicker  
      onChange={this.handleChange}
      selected={this.state.selected}
      minDate={this.state.startDate}
      maxDate={this.state.endDate}
      filterDate={this.state.allowedDates}
      onMonthChange={this.handleMonthChange}
      calendarClassName="customPicker" 
      inline /> : <></>
    }
    
    
    </>)
  }
}