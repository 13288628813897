import React from "react";
import { Country, State, City }  from 'country-state-city';
import { useParams } from 'react-router';
import contries from '../json/country_isd.json';
import Login from "./Login";
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


class FeedBack extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            verified: false,
            data: {},
            ticket: {},
            people: []
        }
        console.log(Country.getAllCountries());
    }

    componentDidMount(){
        
        this.id = this.props.params.id;
        if(this.props.global.user!== null){
            this.getTicket({ 
                _id: { 'ObjectId': this.id },
                uid: this.props.global.user.uid 
            });
        }
    }

    syncmodel = (e) => {
        e.preventDefault();
        let id = e.target.getAttribute('name');
        id = id === null || id === undefined ? e.target.getAttribute('id') : id;
        let data = this.state.data;
        data[id] = e.target.value;
        console.log(data);
        this.setState({
            data: data
        });
    }

    findbooking = async (e) => {
        e.preventDefault();
        this.getTicket(this.id);
    }

    getTicket = async (_id = false, mobile = false) => {
        mobile = mobile ? mobile : `${this.state.data.countrycode}-${this.state.data.mobile}`;
        let filter = !_id || typeof(_id) !== 'object' ? { _id: { 'ObjectId': _id } , mobile: { "$regex" :mobile } } : _id ;
        //console.log(this.props.global.user.accessToken);
        let ticket = await this.props.global._getmongo("findone", 'bookings', filter);
        if (ticket.ok) {
            ticket = await ticket.json();
            if(ticket._id !== null && ticket._id !== undefined){
                this.setState({
                    ticket: ticket,
                    verified: true
                })
            }
            
        }
    }
    
    render(){
        return (<>
            <main>
            <section className="contents">
                <div className="container">
                    <h1 className="page-title">
                       Feedback Form
                    </h1>
                    <hr />
                    {
                        !this.state.verified ?
                        <>
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                Login to continue
                                <Login authcheck={this.props.authcheck} global={this.props.global} />
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                        </> :
                        <>
                        <form>
                            <h4>Booking ID: {this.state.ticket._id}</h4>
                            <hr />
                            { 
                                this.state.ticket.status >= 3 ? 
                                <>
                                <form>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <ul className="list-group">
                                                <li className="list-group-item">
                                                    <label>Do you know about Sri Sathya Sai Baba?</label>
                                                    <br />
                                                    <input type={'radio'} name="knowsai" id="knowsaiyes" value={'Yes'} onChange={this.syncmodel} checked={this.state.data.knowsai === "Yes"} /> <label htmlFor="knowsaiyes">Yes</label>&nbsp; &nbsp;
                                                    <input type={'radio'} name="knowsai" id="knowsaino" value={'No'} onChange={this.syncmodel} checked={this.state.data.knowsai === "No"} /> <label htmlFor="knowsaino">No</label>
                                                </li>
                                                <li className="list-group-item">
                                                <label>Have you visited Sri Sathya Sai baba brindavan ashram before?</label>
                                                <br />
                                                    <input type={'radio'} name="visitedbrindavan" id="visitedbrindavanyes" value={'Yes'} onChange={this.syncmodel} checked={this.state.data.visitedbrindavan === "Yes"} /> <label htmlFor="visitedbrindavanyes">Yes</label>&nbsp; &nbsp;
                                                    <input type={'radio'} name="visitedbrindavan" id="visitedbrindavanno" value={'No'} onChange={this.syncmodel} checked={this.state.data.visitedbrindavan === "No"} /> <label htmlFor="visitedbrindavanno">No</label>
                                                </li>
                                                <li className="list-group-item">
                                                <label>Are you connected with Sri Sathya Sai Organizations?</label>
                                                <br />
                                                    <input type={'radio'} name="ssso" id="sssoyes" value={'Yes'} onChange={this.syncmodel} checked={this.state.data.ssso === "Yes"} /> <label htmlFor="sssoyes">Yes</label>&nbsp; &nbsp;
                                                    <input type={'radio'} name="ssso" id="sssono" value={'No'} onChange={this.syncmodel} checked={this.state.data.ssso === "No"} /> <label htmlFor="sssono">No</label>
                                                </li>
                                                <li className="list-group-item">
                                                    <label htmlFor="ssso_country">Country</label>
                                                    <input className="form-control" type={'text'} id="ssso_country" name="ssso_country" value={this.state.data.ssso_country} />
                                                    <label htmlFor="ssso_country">State</label>
                                                    <input className="form-control" type={'text'} id="ssso_state" name="ssso_state" value={this.state.data.ssso_country} />
                                                </li>
                                                <li className="list-group-item">
                                                <label>Are you using Prasanthi Connect App?</label>
                                                </li>
                                                <li className="list-group-item">
                                                <label>Please share your experience in Sri Sathya Sai Divyasmrti museum:</label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-12">
                                            
                                        </div>
                                    </div>
                                </form>
                                </> :
                                <div className="alert alert-danger text-center">Please share your feedback after visiting Sri Sathya Sai Divyasmṛti Digital Experience Centre</div>
                            }
                        </form>
                        </>
                    }
                </div>
            </section>
            </main>
        </>);
    }
}

export default withParams(FeedBack);