
import initApp from "./initApp";
import { signInWithEmailAndPassword, updateProfile, signOut, createUserWithEmailAndPassword, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, sendPasswordResetEmail } from "firebase/auth";

class Global extends initApp {
  constructor(props) {
    super(props);
    this.query = this._queryString();

    this.meta = {
      title: this.env.site.title,
      description: this.env.site.description,
      keywords: this.env.site.keywords
    }
    this.auth = this.init();
  }

  _queryString = (qry = '') => {
    const params = new URLSearchParams(window.location.search);
    if (qry === '') {
      const result = {}
      for (const [key, value] of params.entries()) { // each 'entry' is a [key, value] tupple
        result[key] = value;
      }
      return result;
    } else {
      return params.get(qry);
    }

  }

  async getInbox(region = 'default') {
    //{"num_inboxes":1,"inboxes":[{"id":10,"number":919220592205,"keyword":"XM498","num_messages":0,"num_contacts":0,"inboxName":"","newMsgs":0,"isMyDND":"n"}],"status":"success"}
    let textlocal = this.env.textlocal;
    //console.log(textlocal[region]);
    textlocal = textlocal[region];
    let api = textlocal.api;
    let inbox = textlocal.inbox;
    let host = textlocal.host;
    const addMinutes = function (m = 1, dt = false) {
      dt = dt ? dt : new Date();
      dt.setMinutes(dt.getMinutes() + m);
      return dt;
    }
    var min_time = Math.floor(addMinutes(-5).getTime() / 1000);
    var max_time = Math.floor(addMinutes(5).getTime() / 1000);
    let req = `${host}/get_messages/?apikey=${api}&inbox_id=${inbox}&limit=100&min_time=${min_time}&max_time=${max_time}`;
    
    let response = await fetch(req, {
      method: "GET"
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
    });
    this.smsinbox = await response;
  }

  filterDuplicate(arr) {
    let f = [];
    let v;
    arr.forEach((e) => {
      if (JSON.stringify(e) !== JSON.stringify(v)) {
        f.push(e);
      }
      v = e;
    });

    return Array.isArray(f) && f[0] !== undefined ? f[0] : f;
  }

  async processBooking(data) {
    const uid = this.user.uid;
    let people = data.people;

    let date = data.date;
    let slot = JSON.parse(data.slot);
    const mobile = data.mobile;
    if (people.length > slot.available) {
      this.bookingValidation = 'Number of people are more than allowed per slot';
      return false;
    } else {
      data = {
        prefix: this.prefix(''),
        bookingSlot: date + ' ' + slot.slot,
        date: date,
        slot: slot.slot,
        uid: uid,
        sendmail: true,
        people: people,
        mobile: mobile
      }
      
      let response = await this.postReq('/dobooking', { post: data });
      if (response.ok) {
        response = response.json();
        ///window.localStorage.removeItem('formdata');
       //// window.localStorage.removeItem('people');
       /// window.localStorage.removeItem('selectedate');
        
        return response;
      } else {
        return false;
      }
    }

    //data.forEach((row)=>{

    // });


  }

  async getAvailableDates() {
    const today = (new Date());
    let data = await this._getmongo('distinct', 'AvailableSlots',
      { field: '_id', qry: { "$expr": { "$gt": [{ "$dateFromString": { "dateString": "$_id" } }, { "$dateFromString": { "dateString": today } }] }, "$where": "this.Booked < this.Available" } },
      { Date: -1 });
    let dates = [];
    if (data.ok) {
      data = await data.json();
      if(Array.isArray(data)){
        data.forEach((x)=>{
          x = x.split(' ');
          dates.push(x);
        })
      }
      console.log(dates);
      return dates;
    } else {
      return [];
    }

    //return await data;
  }

  getMyBookings = async () => {
    if (this.user !== undefined && this.user !== null) {
      const uid = this.user.uid;
      let data = this._getmongo('sortfind', 'bookings', { uid: uid }, { bookingDate: -1 });
      this.bookings = await data.then((dt) => {
        if (dt.ok) {
          return dt.json();
        }
      }).catch(err => {
        
        return err;
      });
    } else {
      return [];
    }

  }

  getBookingDetail = async (bkid) => {
    let data = this._getmongo('find', 'bookingPeople', { fid: bkid });
    return await data.then((dt) => {
      if (dt.ok) {
        return dt.json();
      }
    }).catch(err => {
      
      return err;
    });
  }

  formattedTime = (date, add = 0, f = [2, 1, 0]) => {
    
    if (date === undefined) {
      return;
    }
    date = date.split(', '); //split date time
    if (date[1] === undefined) {
      return;
    }

    // date[0] = date[0].split('/');
    //  date[0] = [date[0][f[0]],date[0][f[1]],date[0][f[2]]];
    // date[0] = date[0].join('-');
    date[1] = date[1].split(' ');
    //  let h = 0;
    // h = date[1][1] == 'PM' ? 12 + h : h;
    date[1][0] = date[1][0].split(':');
    date[1][0][0] = date[1][1] === 'PM' ? 12 + parseInt(date[1][0][0]) : date[1][0][0];
    date[1][0][0] = date[1][0][0] === 24 ? 12 : date[1][0][0];
    date[1][0][1] = parseInt(date[1][0][1]) + parseInt(add);
    date[1][0][0] = date[1][0][1] >= 60 ? (parseInt(date[1][0][0]) + Math.floor(parseInt(date[1][0][1]) / 60)) : date[1][0][0];
    date[1][0][1] = date[1][0][1] >= 60 ? parseInt(date[1][0][1]) - (Math.floor(parseInt(date[1][0][1]) / 60) * 60) : date[1][0][1];
    date[1][0][0] = date[1][0][0] < 10 ? '0' + date[1][0][0] : date[1][0][0];
    date[1][0][1] = date[1][0][1] < 10 ? '0' + date[1][0][1] : date[1][0][1];
    date[1][0] = date[1][0].join(':');
    date[1] = date[1][0];
    //date = date.join(' ');
    return date[1];

  }



  async getSlots(date) {

    let ctime = this.formattedTime(new Date().toLocaleString(false, { timeZone: 'Asia/Kolkata' }), 30); // after 15 mins from now
    //date = date === undefined ? new Date() : date + ctime;
    let cdate = new Date();
    let cmon = parseInt(cdate.getMonth()) + 1;
    
    cdate = [
      cdate.getFullYear(),
      cmon < 10 ? '0' + cmon : cmon,
      cdate.getDate() < 10 ? '0' + cdate.getDate() : cdate.getDate()
    ]

    ctime = cdate.join('-') + 'T' + ctime + '.' + new Date().toISOString().split('.')[1];
    //ctime = new Date(ctime).toISOString();
    
    /*let data = this._getmongo('find', 'AvailableSlots', { "$expr": { "$gt": [{ "$dateFromString": { "dateString": "$_id" } }, { "$dateFromString": { "dateString": ctime } }] }, Date: date }, { Slot: 1 });
    */
    let data = this._getmongo('find', 'AvailableSlots',
    { "$expr": { "$gt": [{ "$dateFromString": { "dateString": "$_id" } }, { "$dateFromString": { "dateString": ctime } }] }, "$where": "this.Booked < this.Available", Date: date } , { Slot: 1 });


    let slots = data.then(async (dt) => {
      if (dt.ok) {
        //console.log(await dt.json());
        return dt.json();
      }
    }).catch(err => {
      
      return err;
    });
    return await slots;
  }

  postReq = async (req, params = {}) => {
    const host = params.host !== undefined ? params.host : this.host;
    const headers = params.headers !== undefined ? params.headers : this.headers;

    this.user = this.user !== null ? this.user : this.fAuth.currentUser;
    if (this.user !== null) {
      headers.authtoken = this.user.accessToken;
    }


    const body = JSON.stringify(params.post);
    let response = async () => {
      return await fetch(host + req, {
        method: "POST",
        headers: headers,
        body: body
      });
    }
    return await response();
    /*  response().then((resp)=>{
        if(resp.ok){
          const rjson = async () => { await resp.json(); }
            return rjson();
        }else{
            //alert('Error: ' + resp.status);
            
            return resp;
        }
      })*/



  }

  _setmongo = async (action, col, data, qry = '') => {
    /* 
    'createCollection',dropCollection,insertone,insertmany 
    updateone updatemany deleteone 'deletemany': */
    return await this.postReq("/set", {
      post: {
        action: action,
        collection: this.prefix(col),
        qry: qry,
        data: data
      }
    });
  }

  _getmongo = async (action, col, qry, order = false, limit = false) => {
    /*
    sortFind,joinfind,find,findone
    */
    return await this.postReq("/get", {
      post: {
        action: action,
        collection: this.prefix(col),
        qry: qry,
        order: order,
        limit: limit
      }
    });

  }

  getReq = async (req, params = {}) => {
    const host = params.host !== undefined ? params.host : this.host;
    const headers = params.headers !== undefined ? params.headers : this.headers;
    if (this.user !== null) {
      headers.authtoken = this.user.accessToken;
    }
    const body = params.post;
    let response = await fetch(host + req + new URLSearchParams(body), {
      method: "GET",
      headers: headers
    });
    if (response.ok) {
      return await response.json();
    } else {
      alert('Error: ' + response.status);
      
      return response;
    }
  }

  fireVerifyPasswordLess = (uid) => {

    uid = uid == null ? false : uid.uid;

    if (!uid && isSignInWithEmailLink(this.fAuth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }

      return signInWithEmailLink(this.fAuth, email, window.location.href)
    }
  }

  firePasswordLess = async (email) => {
    return sendSignInLinkToEmail(this.fAuth, email, this.fAC)
  }

  resetPassword = async (email) => {
    return await sendPasswordResetEmail(this.fAuth, email);
  }

  fireError(err) {
    let msg;
    switch (err) {
      case 'Firebase: Error (auth/wrong-password).':
        msg = 'Password is wrong';
        break;
      case 'Firebase: Error (auth/user-not-found).':
        msg = 'User is not available!';
        break;
      case 'Firebase: Error (auth/invalid-action-code).':
        msg = 'Authentication code is expired or invalid! Get New link from the login again and try to login with new link.';
        break
      default:
        msg = err;
        break;
    }
    return msg;
  }

  setPageTitleNSEO(title = '', description = '', keywords = '') {
    title = title === '' ? this.meta.title : title;
    description = description === '' ? this.meta.description : description;
    keywords = keywords === '' ? this.meta.keywords : keywords;
    this.meta = {
      title: title, description: description, keywords: keywords
    }
  }

  firebaseLoginPassword = async (email, password) => {
    return await signInWithEmailAndPassword(this.fAuth, email, password);
  }

  fireRegisterEmail = async (email, password = '') => {
    return await createUserWithEmailAndPassword(this.fAuth, email, password);
  }
  fireUpdateProfile = async (name) => {
    return await updateProfile(this.user, { displayName: name });
  }
  fireSignOut = async () => {
    return await signOut(this.fAuth);
  }

}

export default Global;
