import React from 'react';

export default class NavHome extends React.Component {
    constructor(props) {
        super(props);
        this.config = this.props.global.env;
        this.hostname = this.config.site.host;
        this.__ = (str = "") => {
            return this.config.homeurl + str;
        }
    }
    render() {
        return (<>
            <div className="hamenu">
                <div className="container">
                    <div className="menu-close">CLOSE <span>X</span></div>
                    <div className="row">
                        <div className="col-lg-9 col-md-8">
                            <div className="menu-links">
                                <ul className="main-menu">
                                    <li>
                                        <div className="o-hidden">
                                            <a href={this.__()}><span className="link">
                                                Home
                                            </span></a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="o-hidden">
                                            <span className="link dmenu">About <i className="fa fa-angle-right"></i></span>
                                        </div>
                                        <div className="sub-menu">
                                            <ul>
                                                <li>
                                                    <div className="o-hidden">
                                                        <span className="sub-link back"><i className="fa fa-angle-left"></i> Go
                                                            Back</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="o-hidden">
                                                        <a href={this.__("/about/sri-sathya-sai")} className="sub-link">Sri Sathya Sai</a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="o-hidden">
                                                        <a href={this.__("/about/museum")} className="sub-link">Digital Museum</a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="o-hidden">
                                                        <a href={this.__("/about/brindavan-ashram")} className="sub-link">Brindavan Ashram</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="o-hidden">
                                            <span className="link dmenu">Visit <i className="fa fa-angle-right"></i></span>
                                        </div>
                                        <div className="sub-menu">
                                            <ul>
                                                <li>
                                                    <div className="o-hidden">
                                                        <span className="sub-link back"><i className="fa fa-angle-left"></i> Go
                                                            Back</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="o-hidden">
                                                        <a href={this.__("/visit/getting-here")} className="sub-link">Getting Here</a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="o-hidden">
                                                        <a href={this.__("/visit/general-info")} className="sub-link">General Info</a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="o-hidden">
                                                        <a href={"https://booking.sssdivyasmrti.org"} target="_blank" rel="noopener noreferrer" className="sub-link">Online Booking</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="o-hidden">
                                            <span className="link dmenu">Gallery <i className="fa fa-angle-right"></i></span>
                                        </div>
                                        <div className="sub-menu">
                                            <ul>
                                                <li>
                                                    <div className="o-hidden">
                                                        <span className="sub-link back"><i className="fa fa-angle-left"></i> Go
                                                            Back</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="o-hidden">
                                                        <a href={this.__("/gallery/photos")} className="sub-link">Photos</a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="o-hidden">
                                                        <a href={this.__("/gallery/videos")} className="sub-link">Videos</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="o-hidden">
                                            <a href={"https://booking.sssdivyasmrti.org"} target="_blank" rel="noopener noreferrer"><span className="link highlight">
                                                Online Booking
                                            </span></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4">
                            <div className="cont-info">
                                <div className="item">
                                    <h6>Timings :</h6>
                                    <p>09:00 AM to 05:00 PM</p>
                                </div>
                                <div className="item">
                                    <h6>Whatsapp :</h6>
                                    <p><a href="https://wa.me/919393258258">9393258258</a></p>
                                </div>
                                <div className="item">
                                    <h6>Address :</h6>
                                    <p>&apos;Brindavan&apos;, Kadugodi Post, Kadugodi, Bengaluru, Karnataka 560067</p>
                                </div>
                                <div className="item">
                                    <h6>Email :</h6>
                                    <p><a href="mailto:info@sssmediacentre.org">info@sssmediacentre.org</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }

}