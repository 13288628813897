import React from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
//import jsPDF from 'jspdf';

import { useParams } from 'react-router';
//import html2canvas from 'html2canvas';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Bookings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bookings: [],
            loader: true,
            viewticket: false,
            people: [],
            view: {},
            user: null
        };
        if (this.props.params.id) {
            this.ticketID = this.props.params.id
            this.viewTicket(this.ticketID);
        }
        //this.ticketID = this.props.global.query.view !== undefined ? this.props.global.query.view: false ;
        this.gender = { M: 'Male', F: 'Female', O: 'Other' };
        this.url = {
            validate: this.props.global.adminurl + '/validate/',
            checkin: this.props.global.adminurl + '/checkin/',
            checkout: this.props.global.adminurl + '/checkout/'
        }
        this.docType = { IN: 'ADHAAR', OTHER: 'PASSPORT' }



    }

    componentDidMount() {
        this.setState({
            user: this.props.global.user
        });
        if (!this.state.loader) {
            
            const bookings = this.loadBookings();
            bookings.then((res) => {
                
                this.setState({
                    bookings: res,
                    viewticket: this.ticketID,
                    loader: false
                })
            });
        }
    }


    createQR = (data, color = false, bgcolor = 'ffffff') => {
        color = color ? color : '000000';
        data = encodeURI(data);
        return `https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${data}&color=${color}&bgcolor=${bgcolor}`;
    }

    statusColor = (st) => {
        st = !isNaN(st) ? parseInt(st) : 0;
        let cl;
        switch (st) {
            case -1:
                cl = 'DC143C'
                break;
            case 1:
                cl = '6B8E23'
                break;
            case 2:
                cl = 'DC143E'
                break;
            case 3:
                cl = '6B8E23'
                break;
            case 4:
                cl = '006400'
                break;
            default:
                cl = '004071'
                break;
        }
        return cl;
    }

    viewTicket = async (e = false) => {
        this.setState({ loader: true });
        let id;
        let data;
        if (e.target !== undefined) {
            e.preventDefault();
            const ele = e.target;
            id = ele.getAttribute('id');
            data = JSON.parse(ele.getAttribute('view'));
            let booking = await this.props.global._getmongo('findone', 'bookings', { _id: { 'objectID': id } });
            if (booking.ok) {
                booking = await booking.json();
                
                data['booking'] = booking;
                const people = await this.props.global.getBookingDetail(id);
                this.setState({
                    people: people,
                    loader: false,
                    viewticket: id,
                    view: data
                });
                
            }
        } else {
            id = e;
            let booking = await this.props.global._getmongo('findone', 'bookings', { _id: { 'objectID': id } });
            if (booking.ok) {
                booking = await booking.json();
                
                data = {
                    id: booking._id,
                    slot: booking.slot,
                    date: booking.bookingDate,
                    status: booking.status === null || booking.status === undefined ? 0 : booking.status
                };
                data['booking'] = booking;
                const people = await this.props.global.getBookingDetail(id);
                this.setState({
                    people: people,
                    loader: false,
                    viewticket: id,
                    view: data
                });
                
            }

        }

        

    }

    allbookings = (e) => {
        this.setState({ viewticket: false });
    }

    cancelTicket = (e) => {
        e.preventDefault();
        const ele = e.target;
        let prompt = window.prompt('Type "cancel" to Cancel the ticket: ' + ele.getAttribute('id'));
        if (prompt === null) {
            return;
        }
        if ('cancel' === prompt.toLowerCase()) {
            alert('cancelled: ' + ele.getAttribute('id'));
        } else {
            this.cancelTicket(e);
        }
    }

    loadBookings = async () => {
        await this.props.global.getMyBookings();

        return this.props.global.bookings;
    }

    printDocument() {
        window.print();
        /*var elementHTML = document.getElementById('printArea');
      
        html2canvas(elementHTML, {
          useCORS: true,
          onrendered: function(canvas) {
            var pdf = new jsPDF('p', 'pt', 'A4');
      
            var pageHeight = 980;
            var pageWidth = 900;
            for (var i = 0; i <= elementHTML.clientHeight / pageHeight; i++) {
              var srcImg = canvas;
              var sX = 0;
              var sY = pageHeight * i; // start 1 pageHeight down for every new page
              var sWidth = pageWidth;
              var sHeight = pageHeight;
              var dX = 0;
              var dY = 0;
              var dWidth = pageWidth;
              var dHeight = pageHeight;
      
              window.onePageCanvas = document.createElement("canvas");
              onePageCanvas.setAttribute('width', pageWidth);
              onePageCanvas.setAttribute('height', pageHeight);
              var ctx = onePageCanvas.getContext('2d');
              ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
      
              var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);
              var width = onePageCanvas.width;
              var height = onePageCanvas.clientHeight;
      
              if (i > 0) // if we're on anything other than the first page, add another page
                pdf.addPage(612, 864); // 8.5" x 12" in pts (inches*72)
      
              pdf.setPage(i + 1); // now we declare that we're working on that page
              pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width * .62), (height * .62)); // add content to the page
            }
                  
            // Save the PDF
            pdf.save('document.pdf');
          }
        });*/
    }

    render() {
        let loader = '';
        if (this.state.loader) {
            loader = <div className="preloader">
            <div className="loader" id="loader-1"></div>
          </div>
        }
        return (
            (this.props.global.user === null || this.props.global.fAuth === undefined) && (!this.props.params.id) ?
                (
                    <Navigate to='/' />
                ) :
                (
                    <div className='booking-container'>

                        {this.state.viewticket ?
                            (<>
                                <section className='viewTicket'>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className='only-print text-center'>
                                                    <img alt='Logo' style={{ maxWidth: '300px' }} src={window.location.protocol + '//' + window.location.host + '/assets/gold_logo.png'} />
                                                </div>
                                                <div className="mt-5"></div>
                                                <h3 className="text-center section-heading"><span>Ticket Details</span></h3>
                                                <div className='text-center mt-3'>Wishing you a memorable digital divine experience!</div><br />
                                                <div className='text-left'>
                                                    <div className='row'>
                                                        <div className='col-6'>

                                                            <h4>Remember:</h4>
                                                        </div>
                                                        <div className='col-6'>
                                                            <div className="text-right">
                                                                <button onClick={this.printDocument} className="btn btn-primary do-not-print"><i className="fa fa-download"></i> Download</button>
                                                            </div>
                                                        </div>
                                                    </div><hr />
                                                    <ul>
                                                        <li>
                                                            Please be at the reception atleast 10 minutes prior to the booked slot
                                                        </li>
                                                        <li>
                                                            Please produce this ticket along with the orignal id proofs used for the booking.
                                                        </li>
                                                    </ul><hr />
                                                </div>
                                                <div className="section-content">

                                                    <div className="mt-5"></div>
                                                    {
                                                        this.state.view.booking !== undefined ?
                                                            <div id="printArea" className='printArea'>
                                                                <div className='row'>
                                                                    <div className='col-md-3 col-sm-4 text-right align-self-top'>


                                                                        <div className='text-center sticky-md-top'>
                                                                            <div className="qrcode">
                                                                                <strong>Date:</strong> {this.state.view.booking.date}<br />
                                                                                <strong>Slot:</strong> {new Date(this.state.view.booking.date + ' ' + this.state.view.booking.slot).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}

                                                                                <br /><br />
                                                                                <img alt="QRCODE" style={{ maxWidth: '100%' }} src={this.createQR(this.state.view.id, 'd7bf55', '52453b')} />
                                                                                <br />
                                                                                <br />
                                                                                <strong>Mobile Number: </strong><br />+{this.state.view.booking.mobile}
                                                                            </div>
                                                                            <br />
                                                                            <strong style={{
                                                                                background: '#d7bf55',
                                                                                display: 'inline-block',
                                                                                padding: '5px 10px'
                                                                            }}>Booking Confirmed!</strong>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-8 col-sm-8 text-left align-self-center'>
                                                                        <div className='row ticket-heading'>
                                                                            <div className='col-md-6'>
                                                                                <strong>Booked @</strong> {new Date(this.state.view.date).toDateString()} {new Date(this.state.view.date).toLocaleTimeString()}
                                                                            </div>
                                                                            <div className='col-md-6'>
                                                                                <strong>Booking ID</strong><br />{this.state.view.id}
                                                                            </div>
                                                                        </div>


                                                                        {Array.isArray(this.state.people) ?
                                                                            this.state.people.map((x, i) => {
                                                                                //let clr = this.statusColor(x.status);
                                                                                return (
                                                                                    <>

                                                                                        <div key={i} className='row person'>
                                                                                            <div className='col-md-12 align-self-center'>
                                                                                                <ul className='booking-people'>
                                                                                                    <li>
                                                                                                        <strong>Name: </strong><br /><small>{x.pname}</small>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <strong>Birthdate &amp; Age: </strong><br /><small>{x.dob} ({this.props.global.getAge(x.dob)} Years)</small>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <strong>Gender: </strong><br />
                                                                                                        {this.gender[x.gender]}
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <strong>Nationality: </strong><br />
                                                                                                        {x.nationality}
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <strong>{this.docType[x.nationality]} #: </strong><br />
                                                                                                        {x.dno}
                                                                                                    </li>

                                                                                                </ul>
                                                                                            </div>


                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                            : (<></>)}
                                                                    </div>

                                                                </div>
                                                                <hr />
                                                                <div className='table-responsive'>
                                                                </div>
                                                            </div>
                                                            : <></>
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>) :
                            (<>
                                {loader}

                                <section id="Booking">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mt-5"></div>
                                                <h3 className="text-center section-heading"><span>{this.props.user === undefined || this.props.user.displayName === null ? 'My' : `${this.props.app.user.displayName}'s`} bookings</span></h3>
                                                <div className="section-content">

                                                    <div className="mt-5"></div>

                                                    <hr />
                                                    <div className='table-responsive'>
                                                        {Array.isArray(this.state.bookings) && this.state.bookings.length > 0 ?
                                                            (<>
                                                                <table className='table table-hover'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className='text-center'>Booking ID</th>
                                                                            <th className='text-center'>Booking Slot</th>
                                                                            <th className='text-center'>Booked @</th>
                                                                            <th className='text-center'>Booking</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.bookings.map((x, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className='text-center'><a href={window.location.protocol + '//' + window.location.host + '/booking/' + x._id}>{x._id}</a></td>
                                                                                    <td className='text-center'>{x.bookingslot}</td>
                                                                                    <td className='text-center'>{new Date(x.bookingDate).toString()}</td>
                                                                                    <td className='text-center'><button className="btn btn-theme-1" onClick={this.viewTicket} view={JSON.stringify({ id: x._id, slot: x.bookingslot, date: new Date(x.bookingDate).toString() })} id={x._id}>View</button><button className="btn btn-theme-2 d-none" onClick={this.cancelTicket} id={x._id}>Cancel</button></td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </>)
                                                            : (<>
                                                                <div className='text-center'>
                                                                    <strong>You don't have any booking yet!</strong><br />
                                                                    <Link to='/'>Do your first booking now!</Link>
                                                                </div>
                                                            </>)}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>)}
                    </div>
                )
        );
    }

}
export default withParams(Bookings);
