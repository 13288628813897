import * as React from 'react';
//import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import { FaSignOutAlt } from 'react-icons/fa';
class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: this.props.global.user
    };

    this.signoff = this.signoff.bind(this);
  }

  signoff = (e) => {
    this.props.global.fireSignOut().then((res) => {
      this.props.global.user = null;
      this.setState({
        isLogin: this.props.global.user
      });

      window.scroll({
        top: window.scrollY > 0 ? 0 : 1,
        left: 0,
        behavior: 'smooth'
      });
    }).catch((err) => {
      
    });

  }

  booknow = () => {

    if (this.props.loginOpen()) {
      this.setState({
        islogin: true
      })
    }
  }

  render() {
    return (
      <nav>
        <span>Sairam {this.state.isLogin && this.state.isLogin.displayName ? this.state.isLogin.displayName : this.state.isLogin ? "User" : "Guest"}!</span>
        <ul className="list-group me-auto mb-2 mb-lg-0">


          {
            this.props.global.user === null || this.props.global.fAuth === undefined ?
              (<>
                <li className="list-item">
                  <button className="btn btn-primary" onClick={this.booknow}>Login to Book</button>
                </li>
              </>) :
              (
                <>
                  <li className="list-item">
                    <Link className="link" to="/Bookings">Booking History</Link>
                  </li>
                  <li className="list-item">
                    <Link className="btn btn-primary" to="/Booking">Book Now</Link>
                  </li>
                  <li className="list-item">
                    <button className="btn btn-primary" onClick={this.signoff} title='Logout'><FaSignOutAlt /> LOGOUT</button>
                  </li>
                </>
              )
          }
        </ul>
      </nav>
    );
  }
}

export default Nav;
