import React from "react";
import { CgWebsite } from 'react-icons/cg';
import { FaBell } from 'react-icons/fa';
import { MdContactMail } from 'react-icons/md';

const footerContent = {
    section1: {
        content: () => {
            const links = [
                { title: 'Sri Sathya Sai Central Trust, Prasanthi Nilayam', link: 'https://www.srisathyasai.org/', target: '_blank' },
                { title: 'Sri Sathya Sai Global Council', link: 'https://www.srisathyasaiglobalcouncil.org/', target: '_blank' },
                { title: 'Sri Sathya Sai Media Centre', link: 'https://www.sssmediacentre.org/', target: '_blank' },
                { title: 'Sri Sathya Sai Institute of Higher Learning', link: 'https://www.sssihl.edu.in/', target: '_blank' },
                { title: 'Sri Sathya Sai Vidya Vahini', link: 'https://learning.srisathyasaividyavahini.org/', target: '_blank' },
                { title: 'Sri Sathya Sai Centre for Human Values', link: 'http://ssschv.srisathyasai.org/', target: '_blank' },
                { title: 'Sri Sathya Sai Super Speciality Hospital, Prasanthigram', link: 'https://psg.sssihms.org.in/', target: '_blank' },
                { title: 'Sri Sathya Sai Super Speciality Hospital, Bangalore', link: 'https://sssihms.org/', target: '_blank' },
                { title: 'Sri Sathya Sai Mobile Hospital', link: 'http://www.sssmh.org.in/', target: '_blank' },
                { title: 'Sri Sathya Sai Seva Organisations, India', link: 'https://ssssoindia.org/', target: '_blank' },
                { title: 'Sri Sathya Sai Sadhana Trust - Publications Division', link: 'https://www.srisathyasaipublications.com/', target: '_blank' }
            ];
            return (
                <div>
                    <h5 className="heading text-uppercase"><span><i className="fa fa-object-group"></i> Sister Websites</span></h5>
                    <ul className="navmenu">
                        {links.map((x, i) => {
                            return <li key={i}><a href={x.link} rel="noopener noreferrer" target={x.target}>{x.title}</a></li>
                        })}
                    </ul>
                </div>
            )
        }
    },
    section2: {
        content: () => {
            const links = [
                
            ];
            return (
                <>
                <div>
                    <h5 className="heading text-uppercase"><span><i className="fa fa-map-marker"></i> Address</span></h5>
                    <div>
                        
                  Sri Sathya Sai Baba Ashram, ‘Brindavan’, Kadugodi, Bengaluru, Karnataka 560067
                
                    </div>
                </div>
                <div>
                    <h5 className="heading text-uppercase"><span><i className="fa fa-location-arrow"></i> LOCATION</span></h5>
                    <div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124403.0569295347!2d77.68898085917803!3d12.997702018179057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0e3d7a92bb21%3A0x5de6c6f986b9f6cd!2sSri%20Sathya%20Sai%20Baba%20Ashram!5e0!3m2!1sen!2sin!4v1573567039698!5m2!1sen!2sin" width="100%" height="250" frameBorder="0" style={{border: "0px"}}></iframe>
                    </div>
                </div>
                </>
            )
        }
    },
    section3: {
        content: () => {
            return (
                <div>
                    <h5 className="heading text-uppercase"><span><i className="fa fa-fax"></i> Contact</span></h5>
                    <div>
                    <ul class="list-items mb-0 pl-0">
                        <li class="list-item"><i class="fa fa-envelope-open-text"></i> <a target="_blank" rel="noopener noreferrer" href="mailto:info@sssmediacentre.org" class="sub-link"><span>info@sssmediacentre.org</span></a></li>
                    
                        <li class="list-item"><i class="fab fa-whatsapp-square"></i> <a target="_blank" href="https://wa.me/9393258258" rel="noopener noreferrer" class="sub-link"><span>9393258258</span></a></li>
                    </ul>
                    </div>
                    
                    <div class="widget">
                    <h5 className="heading text-uppercase"><span><i className="fa fa-clock"></i> Timings</span></h5>
                    9.30 AM to 05:00 PM<br />
                  <br />
              
                    <div class="text-left text-md italic" style={{fontSize: "15px"}}>
                        <sup>(*) Notes</sup>
                        <ul>
                        <li>Please bring a valid identity proof when visiting the museum.</li>
                      <li>We recommend making an online booking to save time and secure a confirmed slot during peak hours.</li>
                      <li>Bookings can be made up to 1 hour and 30 minutes before the next available time slot, subject to availability.</li>
                      <li>Entry will close one hour before the museum's closing time. Please arrive on time to access all zones.</li>
                      <li>The museum is open every day except the last Tuesday of each month, though we advise <a href="https://www.sssdivyasmrti.org/booking.html">checking the booking section</a> before your visit.</li>
                        </ul>
                    </div>
                    </div>
                </div>
            )
        }
    }
}
export default footerContent;