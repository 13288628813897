import React from 'react';
import { Link } from 'react-router-dom';
//import { Navigate } from 'react-router-dom';
//import jsPDF from 'jspdf';

import { useParams } from 'react-router';
import Login from './Login';
//import html2canvas from 'html2canvas';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class BookingList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bookings: [],
            loader: false,
            viewticket: false,
            people: [],
            view: {},
            user: this.props.global.user
        };
        if (this.props.params.id) {
            this.ticketID = this.props.params.id
            this.viewTicket(this.ticketID);
        }
        //this.ticketID = this.props.global.query.view !== undefined ? this.props.global.query.view: false ;
        this.gender = { M: 'Male', F: 'Female', O: 'Other' };
        this.url = {
            validate: this.props.global.adminurl + '/validate/',
            checkin: this.props.global.adminurl + '/checkin/',
            checkout: this.props.global.adminurl + '/checkout/'
        }
        this.docType = { IN: 'ADHAAR', OTHER: 'PASSPORT' }



    }

    componentDidMount() {
        if (!this.state.loader) {
            
            const bookings = this.loadBookings();
            bookings.then((res) => {
                
                this.setState({
                    bookings: res,
                    viewticket: this.ticketID,
                    loader: false
                })
            });
        }
    }


    createQR = (data, color = false, bgcolor = 'ffffff') => {
        color = color ? color : '000000';
        data = encodeURI(data);
        return `https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${data}&color=${color}&bgcolor=${bgcolor}`;
    }

    statusColor = (st) => {
        st = !isNaN(st) ? parseInt(st) : 0;
        let cl;
        switch (st) {
            case -1:
                cl = 'DC143C'
                break;
            case 1:
                cl = '6B8E23'
                break;
            case 2:
                cl = 'DC143E'
                break;
            case 3:
                cl = '6B8E23'
                break;
            case 4:
                cl = '006400'
                break;
            default:
                cl = '004071'
                break;
        }
        return cl;
    }


    allbookings = (e) => {
        this.setState({ viewticket: false });
    }

    cancelTicket = (e) => {
        e.preventDefault();
        const ele = e.target;
        let prompt = window.prompt('Type "cancel" to Cancel the ticket: ' + ele.getAttribute('id'));
        if (prompt === null) {
            return;
        }
        if ('cancel' === prompt.toLowerCase()) {
            alert('cancelled: ' + ele.getAttribute('id'));
        } else {
            this.cancelTicket(e);
        }
    }

    loadBookings = async () => {
        await this.props.global.getMyBookings();

        return this.props.global.bookings;
    }

    printDocument() {
        window.print();
        /*var elementHTML = document.getElementById('printArea');
      
        html2canvas(elementHTML, {
          useCORS: true,
          onrendered: function(canvas) {
            var pdf = new jsPDF('p', 'pt', 'A4');
      
            var pageHeight = 980;
            var pageWidth = 900;
            for (var i = 0; i <= elementHTML.clientHeight / pageHeight; i++) {
              var srcImg = canvas;
              var sX = 0;
              var sY = pageHeight * i; // start 1 pageHeight down for every new page
              var sWidth = pageWidth;
              var sHeight = pageHeight;
              var dX = 0;
              var dY = 0;
              var dWidth = pageWidth;
              var dHeight = pageHeight;
      
              window.onePageCanvas = document.createElement("canvas");
              onePageCanvas.setAttribute('width', pageWidth);
              onePageCanvas.setAttribute('height', pageHeight);
              var ctx = onePageCanvas.getContext('2d');
              ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
      
              var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);
              var width = onePageCanvas.width;
              var height = onePageCanvas.clientHeight;
      
              if (i > 0) // if we're on anything other than the first page, add another page
                pdf.addPage(612, 864); // 8.5" x 12" in pts (inches*72)
      
              pdf.setPage(i + 1); // now we declare that we're working on that page
              pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width * .62), (height * .62)); // add content to the page
            }
                  
            // Save the PDF
            pdf.save('document.pdf');
          }
        });*/
    }

    render() {
        let loader = '';
        if (this.state.loader) {
            loader = <div className="preloader">
            <div className="loader" id="loader-1"></div>
          </div>
                    
        }
        return (
            (this.state.user === null || this.props.global.fAuth === undefined) ?
                (
                    <section id="login">
                        <div className="container w-40 text-center">
                            <div className="row h-100">
                                <div className='col-1 col-md-3'>&nbsp;</div>
                                <div className="col-10 col-md-6 align-self-center">
                                    <div className="mt-5"></div>
                                    <Login authcheck={this.props.authcheck} global={this.props.global} />
                                    <div className="mt-5"></div>
                                </div>
                                <div className='col-1 col-md-3'>&nbsp;</div>
                            </div>
                        </div>
                    </section>
                ) :
                (
                    <div className='booking-container'>
                        (<>
                            {loader}

                            <section id="Booking">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-5"></div>
                                            <h3 className="text-center section-heading"><span>{this.props.user === undefined || this.props.user.displayName === null ? 'My' : `${this.props.app.user.displayName}'s`} bookings</span></h3>
                                            <div className="section-content">

                                                <div className="mt-5"></div>

                                                <hr />
                                                <div className='table-responsive'>
                                                    {Array.isArray(this.state.bookings) && this.state.bookings.length > 0 ?
                                                        (<>
                                                            <table className='table table-hover'>
                                                                <thead>
                                                                    <tr>
                                                                        <th className='text-center'>Booking ID</th>
                                                                        <th className='text-center'>Booking Slot</th>
                                                                        <th className='text-center'>Booked @</th>
                                                                        <th className='text-center'>Booking</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.bookings.map((x, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className='text-center'><a href={window.location.protocol + '//' + window.location.host + '/booking/' + x._id}>{x._id}</a></td>
                                                                                <td className='text-center'>{x.bookingslot}</td>
                                                                                <td className='text-center'>{new Date(x.bookingDate).toString()}</td>
                                                                                <td className='text-center'><a href={this.props.global.__(`booking/${x._id}`)} className="btn btn-theme-1" >View</a><button className="btn btn-theme-2 d-none" onClick={this.cancelTicket} id={x._id}>Cancel</button></td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </>)
                                                        : (<>
                                                            <div className='text-center'>
                                                                <strong>You don't have any booking yet!</strong><br />
                                                                <Link to='/'>Do your first booking now!</Link>
                                                            </div>
                                                        </>)}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>)
                    </div>
                )
        );
    }

}
export default withParams(BookingList);
