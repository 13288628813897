import * as React from 'react';
import Nav from './Nav';
import Slider from './Slider';
import Login from '../Login';
import { FaBars, FaWindowClose } from 'react-icons/fa';
//import { HashLink as Link } from 'react-router-hash-link';
import NavHome from './navhome';
import { Link } from 'react-router-dom';

class HContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            navbarShow: false,
            selectedDate: '',
            header: '',
            user: this.props.global.user
        }
        this.__ = this.props.global.__;
        this.env = this.props.global.env;
        this.url = window.location.protocol + '//' + window.location.host;
        console.log(this.props)
    }


   
    

    componentDidMount() {
        setTimeout(()=>{
            this.setState({
                user: this.props.global.user
            });
        },1000);
        
    }


    handleSubmit = async (e) => {
        e.preventDefault()
        const { name } = e.target.elements;
        this.props.global.fireUpdateProfile(name.value).then((result) => {
            this.setState({});
            
        }).catch(
            (err) => console.log(err)
        );


    }
    closeNav = (event) => {
        this.setState({
            // navbarShow: false
        });
    }
    toggleNav = (event) => {

        this.setState({
            navbarShow: this.state.navbarShow ? false : true,
            selectedDate: this.props.global.selectedDate
        });
    }

    render() {
        return (
            <>
                <div className="navbar-container do-not-print">
                    <div id="navi" className="topnav">
                        <div className="container-fluid">
                            <div className="logo">
                                <a href={this.url}>
                                    <img src={this.url + "/assets/img/logobig.png"} alt="Sri Sathya Sai Divya Smriti" />
                                </a>
                            </div>
                            <div className='main-links'>
                            <ul className='topmenu'>
                            {this.state.user !== null ? <><li><Link to={'/'}>New</Link></li><li><Link to={'/bookings'}>History</Link></li></>: <></>}
                            <li><a title='close' style={{top:'6px'}} className='h2 position-relative' href={this.env.homeurl}><i className='fa fa-window-close'></i></a></li>
                            </ul>
                            </div>
                            
                            <div className="menu-icon d-none"><span className="icon"><i></i><i></i><i></i></span><span className="text" data-splitting="true">Menu</span></div>
                        </div>
                    </div>
                </div>
                <NavHome global={this.props.global} />
                <div id="secondHeader" className="second-header d-none">

                    <div>
                        <button onClick={this.toggleNav} className={!this.state.navbarShow
                            ? "list-toggler" : "list-toggler closed"} type="button" aria-expanded={this.state.navbarShow ? "false" : "true"} aria-label="Toggle navigation">
                            {!this.state.navbarShow ? <FaWindowClose title='Close Menu' /> : <FaBars title='Open Menu' />}
                        </button>
                        <div className={this.state.navbarShow ? "d-none" : "d-block"} >

                            <div className="frow">


                                <Nav loginOpen={this.props.loginOpen} global={this.props.global} />

                            </div>

                        </div>

                    </div>
                </div>
                {window.location.pathname === "/digital-museum" ?
                    (<><Slider /></>) :
                    (<></>)}


                <div className={this.props.doLogin ? 'LoginScreen d-flex' : 'LoginScreen d-none'}>

                    <div className='container'>
                        <button className='closebtn' onClick={this.props.loginClose} title='close'>X</button>
                        {this.props.global.user === null || this.props.global.fAuth === undefined ? (
                            <div>
                                <section id="UserLogin">
                                    <div className="container w70 align-self-center">

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mt-5"></div>
                                                <h3 className="text-center section-heading"><span>Log in to booking</span></h3>
                                                <div className="section-content">
                                                    <div className="mt-5"></div>

                                                    <div className="mt-5"></div>
                                                    <div className='loginForm'>
                                                        <div className="row">
                                                            <div className='col-md-3'></div>
                                                            <div className='col-md-6 text-center'>
                                                                <Login loginClose={this.props.loginClose} authcheck={this.props.authcheck} global={this.props.global} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3'></div>
                                    </div>


                                </section>
                            </div>
                        ) : (
                            <div>
                                <section id="UserLogin">
                                    <div className="container w70 align-self-center">

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mt-5"></div>

                                                <span>Sairam {this.props.global.user.displayName == null ? 'User' : this.props.global.user.displayName}!</span>
                                                <div className="section-content">
                                                    {
                                                        this.props.global.user.displayName == null ?
                                                            (<div>
                                                                <form onSubmit={this.handleSubmit}>
                                                                    <div className="input-group mb-3">
                                                                        <input type='text' name='name' className='form-control' placeholder='By which name should I call you?' />
                                                                        <div className="input-group-append">
                                                                            <button type="submit" className='btn btn-theme-1'>Save Name</button>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-5"></div>
                                                                </form>

                                                            </div>) :
                                                            (<>

                                                                <hr />
                                                                <form onSubmit={this.booking}>
                                                                    <div className='row'>
                                                                        <div className='col-md-4'>
                                                                            <label>Person Name</label>
                                                                            <input type={'text'}
                                                                                className="form-control" required name="pname[]" placeholder="Person's Name" />
                                                                        </div>
                                                                        <div className='col-md-1'>
                                                                            <label>Gender</label>
                                                                            <select name="gender[]"
                                                                                className="form-control" required >
                                                                                <option value={'M'}>M</option>
                                                                                <option value={'F'}>F</option>
                                                                                <option value={'O'}>O</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className='col-md-3'>
                                                                            <label>Nationality</label>
                                                                            <select name="nationality[]"
                                                                                className="form-control" required >
                                                                                <option value={'IN'}>Indian</option>
                                                                                <option value={'OTHER'}>OTHER</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            <label>Document number</label>
                                                                            <input type={'text'}
                                                                                className="form-control" required name="dno[]" placeholder="Aadhar or Passport" />
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </>)
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )
                        }
                    </div>

                </div>
            </>
        );
    }
}

export default HContainer;